<template>
   <div 
    class="social-networks" 
    :class="{ 'lp-centralized': isLPCentralized }"
    v-if="socialNetworks"
   >
        <a 
            v-for="( social, index ) in socialNetworks" 
            :href="social.social_network" 
            :key="index"
            target="_blank"
        >
            <component
                :is="getSocialComponent(social.network)"
                :fill="cssVar()"
                :size="iconSize"
            />
            <q-tooltip 
            v-if="isBigScreen"
            self="top middle" :offset="[0, 5]"
            >
                <span v-text="social.network" />
            </q-tooltip>
        </a>
        <a class="btn-share" @click="share">
          <q-tooltip v-if="$q.screen.gt.sm">
            Compartilhar loja
          </q-tooltip>
          <q-icon name="share" size="1rem" style="color: #3d4c5f" />
        </a>
   </div>
</template>
<script setup>
import { defineProps, defineAsyncComponent, computed } from "vue";

const instagramLogo = defineAsyncComponent(() => import('@/assets/icons/components/instagram.vue'))
const twitterLogo = defineAsyncComponent(() => import('@/assets/icons/components/twitter.vue'))
const facebookLogo = defineAsyncComponent(() => import('@/assets/icons/components/facebook.vue'))
const whatsappLogo = defineAsyncComponent(() => import('@/assets/icons/components/whatsapp.vue'))

const props = defineProps({
  socialNetworks: Object,
  isBigScreen: Boolean, // Para em smarthphones não exibir o tooltip
  isLPCentralized: Boolean
});


/* Mapeando os nomes das redes sociais aos componentes */
const socialComponentMap = {
  instagram: instagramLogo,
  twitter: twitterLogo,
  facebook: facebookLogo,
  whatsapp: whatsappLogo
};

const iconSize = computed(() => props.isLPCentralized ? '1.2rem' : '1.4rem')

/* Função para retornar o componente correto de acordo com a rede social */
const getSocialComponent = (network) => {
  return socialComponentMap[network.toLowerCase()] || null;
}

const share = () => {
  navigator.share({
    url: `${window.location.href}`,
  });
};

const cssVar = () => {
  return '#3d4c5f'
};

</script>
<style scoped>
    .social-networks {
        display: flex;
        gap: 9px;
        margin-top: 5px;
    }

    .q-tooltip span {
      text-transform: capitalize
    }

    .social-networks.lp-centralized .btn-share {
      top: -0.1rem;
      cursor: pointer !important;
      position: relative;
    }

    @media ( min-width: 768px ) {
        .social-networks {
            margin-top: 5px;
            gap: 12px;
        }
    }
</style>